import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.map.js";
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var solutionNames = computed(function () {
      var _props$impact, _props$impact$impactS;
      return (_props$impact = props.impact) === null || _props$impact === void 0 ? void 0 : (_props$impact$impactS = _props$impact.impactSolutions) === null || _props$impact$impactS === void 0 ? void 0 : _props$impact$impactS.map(function (solution) {
        var _solution$concept;
        return (_solution$concept = solution.concept) === null || _solution$concept === void 0 ? void 0 : _solution$concept.label;
      }).filter(function (item) {
        return !!item;
      }).join(', ');
    });
    return {
      solutionNames: solutionNames
    };
  }
});